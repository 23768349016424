import * as React from 'react'
import { Text, Image, VStack } from '@chakra-ui/react'

import Seo from '~components/shared/seo'
import NotFoundImage from '~images/illustrations/not-found.svg'

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <VStack spacing={0}>
      <Image src={NotFoundImage} w="240px" mb={8} />
      <Text fontWeight="bold">404 - Not Found</Text>
      <Text fontSize="sm">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Text>
    </VStack>
  </>
)

export default NotFoundPage
